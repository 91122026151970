// Fonts
@import url(https://fonts.googleapis.com/css?family=Ruda:400,700,900);
@import "resources/assets/css/font-awesome.min";
@import "resources/assets/css/fakeLoader";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Modules
@import "node_modules/fullcalendar/dist/fullcalendar";
@import "node_modules/animate.css/animate.min";
@import "node_modules/sweetalert/dist/sweetalert";
@import "node_modules/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min";
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker";
@import "node_modules/select2/dist/css/select2.min"; 
@import "node_modules/qtip2/dist/jquery.qtip.min"; 
@import "node_modules/orgchart/dist/css/jquery.orgchart";
@import "node_modules/flatpickr/dist/flatpickr.min";
// @import "node_modules/summernote/dist/summernote";
// @import "node_modules/pace-progress/themes/black/pace-theme-corner-indicator";


html {
	overflow: hidden;
}
body {
	background: $brand-primary;
}
legend {
	padding: 5px 15px;
	font-size: 15px;
	font-weight: bold;
	color: #333;
}
#main-navbar {
	padding: 15px;
	height: 50px;
	a {
		font-size: 12px;
		padding: 0px 5px;
		color: #fff;
		&:hover {
			text-decoration: none;
		}
	}
	.btn-group {
		margin: auto 10px;
	}
}

/* The Overlay (background) */
.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */    
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.75); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
	background: #fff;
    position: relative;
    top: 15%; /* 25% from the top */
    left: 20%; /* 25% from the top */
    width: 60%; /* 100% width */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
    padding: 20px;
    border-radius: 5px;

	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}

/* Position the close button (top right corner) */
.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    color: #fff;
    text-decoration: none;
    &:hover {
    	text-decoration: none;
	}
}

#main-wrapper {
	margin: 0px;
	padding: 0px;
	padding-left: 200px;

	#sidebar-wrapper {
		position: fixed;
		width: 215px;
		height: 100%;
		top: 0px;
		left: 0px;
		overflow: hidden;
		.sidebar-company {
			color: #fff;
			text-align: center;
			font-size: 10px;
		}
		.sidebar-heading {
			font-weight: bold;
			width: 100%;
			display: block;
			background: rgba(0, 0, 0, 0.5);
			color: #fff;
			font-size: 14px;
			padding: 10px;
			text-align: center;
			margin: 0px;
			text-decoration: none!important;
		}

		.sidebar-nav {
			margin: 0px;
			padding: 0px; 
			li {
				list-style: none;
				a {
					font-size: 12px;
					padding: 10px 20px;
					display: block;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					border-top: 1px solid rgba(255, 255, 255, 0.1);
					color: rgba(255, 255, 255, 0.75);
					&:hover {
						color: rgba(255, 255, 255, 1);
						text-decoration: none;
						background: rgba(255, 255, 255, 0.1);
					}
				}
				ul {
					margin: 0px;
					padding: 0px;
					li {
						a {
							//background: rgba(0, 0, 0, 0.1);
							padding-left: 30px;
							&:hover {
								//background: rgba(0, 0, 0, 0.25);
							}
						}
					}
				}
			}
		}
	}
	&.toggle {
		padding-left: 0px;
		#sidebar-wrapper {
			width: 0px;
		}
	}
}


.orgchart {
	background: none;
}

.orgchart .node .content {
	height: auto;
	img {
		width: 100%;
	}
	ul, ol {
		margin: 0px;
		padding: 5px 20px;
	}
	li {
		text-align: left;
	}
}

.table-no-spacing {
	border: none;

	thead {
		tr {
			border: none;
			th {
				border: none;
				padding: 0px;
				margin: 0px;
			}
		}
	}

	tbody {
		tr {
			border: none;
			td {
				border: none;
				padding: 0px;
				margin: 0px;
			}
		}
	}
	
}

.panel-ticket {
	font-size: 10px;
	.panel-heading {
		background: #2baea6;
		a, a:hover {
			color: #fff;
		}
	}	
	.panel-body {
		.info {
			margin: 0px;
			text-align: right;
		}
	}
}

.panel-black {
	background: #fafafa;
	border: none;

	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);

	& >.panel-heading {
		background: #141918;
	}

	& > .panel-body {
		min-height: 480px;
	}
}

.panel-heading-nav {
	margin: 0px;
	padding: 3px;
	li {
		list-type: none;
		display: inline-block;
		a {
			font-size: 12px;
			color: #ddd;
			padding: 0px 5px;
			&:hover {
				text-decoration: none;
				color: #fff;
			}
		}
	}
}

.pex-list-group {
	border-top: 2px solid $gray-lighter;
	.list-group-item {
		border-left: 4px solid #00f6ba;
		&.title {
			color: #666;
			font-size: 12px;
			font-weight: bold;
		}
		.list-group-item-heading {
			color: #00f6ba;
			font-size: 14px;
			font-weight: bold;
			padding-top: 10px;
		}
		.list-group-item-text {
			padding-bottom: 10px;
			font-size: 12px;
		}
	}
	&.blue {
		.list-group-item {
			border-left-color: #05f8ea;
			.list-group-item-heading {
				color: #05f8ea;
			}
		}
	}
	&.green {
		.list-group-item {
			border-left-color: #a9d86e;
			.list-group-item-heading {
				color: #a9d86e;
			}
		}
	}
}
.btn-pdca {
	padding: 1px 9px;
}
.table-small {
	font-size: 11px;
}

.nav-box {
    border: 1px solid #ddd;
    margin-top: -20px;
    border-top: none;
    padding: 10px;
    background: #fff;
}

#action-center {
	z-index: 10000;
	display: none;
	
	position: absolute;
	right: 165px;
	top: 32px;

	background: #fff;
	width: 250px;
	border-radius: 5px;

	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);

	.heading {
		background: $brand-danger;
		font-weight: bold;
		color: #fff;
		padding: 15px;
		display: block;
		text-align: center;
	}
	.action-list {
		margin: 0px;
		padding: 0px;
		border-radius: 5px;
		li {
			list-style: none;
			a {
				display: block;
				padding: 10px 15px;
				border-bottom: 1px solid #f9f9f9;
				color: #999;
				font-size: 12px;
				&:hover {
					color: #000;
					text-decoration: none;
				}
			}
		}
	}
}
.family-row {
	display: block;
	padding-left: 30px;
	.process {
		padding: 10px;
		display: block;
		border-bottom: 1px solid #ccc;
		.team {
			display: block;
			padding-left: 20px;
			font-size: 12px;
		}
		.btn {
			float: right;
			margin-left: 5px;
		}
	}
}

.comments-container {
	padding: 10px 0px;
	.comment {
		margin: 10px auto;
		border-bottom: 1px solid #eee;
		.btn-delete {
			float: right;
			border: none;
			background: none;
			color: #666;
		}
		.user {
			padding: 5px;
			padding-bottom: 0px;
			font-size: 13px;
			font-weight: bold;
			color: #666;
			display: inline-block;
		}
		.body {
			font-size: 11px;
			padding: 5px;
		}
		.date {
			color: #999;
			font-size: 9px;
			padding: 5px;
			display: inline-block;
		}
	}
}

.message-container {
	margin-top: 20px;
	padding: 5px;
	.message-header {
		.btn {
			float: right;
			margin-left: 5px;
		}		
	}
	.message-body {
		font-size: 12px;
	}
	.message-footer {
		font-weight: bold;
		font-size: 10px;
		background: #fafafa;
		padding: 10px;
		margin: -10px;
		margin-top: 10px;
		border-bottom: 1px solid #eee;
	}
}

.label-bubble {
	padding: 5px 8px;
	border-radius: 50px;
	position: relative;
	top: -18px;
	margin-left: -23px;
}
#footer {
	color: rgba(255, 255, 255, 0.6);
	a, a:hover {
		color: rgba(255, 255, 255, 0.8);
	}
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */
    translate(0px, 0px)
    /* 45 is really 360 - 45 */
    rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  padding: 5px 10px;
}

@media print {
	#main-wrapper {
		padding: 0px;	
	}
	#sidebar-wrapper, #check-container, #main-navbar, 
	form, hr,
	.btn, .no-print {
		display: none;
	}
	.print {
		display: block;
	}
}